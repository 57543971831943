import React, {useEffect, useState} from 'react';
import axios from 'axios';
import ReactWhatsapp from 'react-whatsapp';


const WhatsAppChat = () => {

    const [whatsappNum, setWhatsappNum] = useState([]);

    useEffect(()=>{
        axios.get("https://www.iicr.co.in/my-admin/fetch-contact.php").then((response)=>{
            setWhatsappNum(response.data.contactresult);
      })
    },[]);

    return(
        <>
        <div className='WhatsappChatIcon'>
        {whatsappNum.map((res)=>
        <>
            <ReactWhatsapp number={res.whats_app} message="">
                <i className="fa fa-whatsapp WhatsAppIcon"></i>
            </ReactWhatsapp>
        </> 
        )}
        </div>

        </>
    );
}


export default WhatsAppChat;