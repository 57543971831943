import React , { useEffect, useState } from 'react';
import { Link,NavLink } from "react-router-dom";

import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Header from './Header';
import Footer from './Footer';
import Title from "./Title";
import ScrollTop from './ScrollTop';
import ContactForm from './ContactForm';
import ContactIcon from './ContactIcon';
import WhatsAppChat from './WhatsAppChat';
import Testimonials from './Testimonials';
import CookiesPopup from './CookiesPopup';
import CadHomeGallery from './CadHomeGallery';


const Home = () => {

    var settings = {

        dots: true,
        infinite: true,
        arrows: false,
        speed: 1000,
        autoplay: true,
        pauseOnHover: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        InitalSlide : 0,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            }
          }
        ]
      };

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant"
        });
      }, []);

    return(
        <>
        <Title title='Welcome To International Institute of Cad And Render!' />
        <ContactIcon />
        <ScrollTop />
        <WhatsAppChat />
        <CookiesPopup />
        <Header />
        <div className='cad-home-banner cad-home-banner-title'>
            <Slider {...settings}>
                <div className='cadBannerHomeSlider slide4'>
                    <img src='/assets/images/home-banner-1.jpg' />
                    {/* <div className='container clearfix'>
                        <div className='cad-home-banner-text cad-home-banner-inner col-12'>
                            <h1>Welcome to IICR</h1>
                        </div>
                    </div> */}
                </div>
                <div className='cadBannerHomeSlider slide1'>
                    <img src='/assets/images/home-banner.webp' />
                    <div className='container clearfix'>
                        <div className='cad-home-banner-text cad-home-banner-inner col-12'>
                            <h1>Learn Jewelry Designing</h1>
                        </div>
                    </div>
                </div>
                <div className='cadBannerHomeSlider slide2'>
                    <img src='/assets/images/home-banner-2.jpg' />
                    <div className='container clearfix'>
                        <div className='cad-home-banner-text cad-home-banner-inner col-12'>
                            <h1>Professional Courses</h1>
                        </div>
                    </div>
                </div>
                <div className='cadBannerHomeSlider slide3'>
                    <img src='/assets/images/courses-banner.webp' />
                    <div className='container clearfix'>
                        <div className='cad-home-banner-text cad-home-banner-inner col-12'>
                            <h1>Turn Ideas To Real Designs</h1>
                        </div>
                    </div>
                </div>
            </Slider>
        </div>

        <div className='cad-welcome-section'>
            <div className='container'>
                <h1>Welcome to International Institute of CAD and Render!</h1>
                <p className='cad-welcome-section-para'>Are you passionate about jewelry designing and eager to explore the endless possibilities offered by Manual Sketching and Computer-Aided Design (CAD)? Look no further! IICR is your ultimate destination for mastering the art of jewelry design using up-to-date designing techniques and cutting-edge CAD software.</p>
            </div>
        </div>

        <div className='cad-about-section'>
            <div className='container'>
                <div className='row cad-about-section-align'>                   
                    <div className='cad-about-section-left col-6'>
                        <div className='cad-about-instructor-img'>
                            <Link to="/about-us"><img src="/assets/images/about-us.webp" /></Link>
                        </div>
                    </div>
                    <div className='cad-about-section-right col-6'>
                        <div className='cad-about-section-right-inner cad-home-banner-inner'>
                            <span>About</span>
                            <h2>About Us</h2>
                            <p>We at International Institute of CAD and RENDER are a team of Professional Jewelry Designers and CAD Artists who are in the jewelry designing and teaching industry for more than 10 years. We have thorough knowledge of the jewelry manufacturing process which allows us to teach you on how to focus on the intricate details and minute technical areas which are crucial for the functionality of any design and hence make it 100% practical.</p>
                            <p>We are focused on empowering aspiring individuals to unlock their ability and become masters of Jewelry Designing by the means of Manual Designing and 3D CAD Modeling. Our dedication towards providing you comprehensive learning helps you hone the knowledge and practical skills you need to excel in the jewelry industry.</p>
                        </div> 
                    </div>
                </div>
            </div>
        </div>

        <div className='cadBgVd-section'>
            <video className='videoTag' autoPlay loop muted>
                <source src="assets/images/home-page-video.mp4" type='video/mp4' />
            </video>
        </div>

        <div className='cad-wwo cad-process'>
            <div className='container'>
                <div className='cad-process-header cad-about-section-right-inner cad-home-banner-inner'>
                    <span>Courses</span>
                    <h2>Our Courses</h2>
                    <div className='row cad-process-align'>
                        <div className='cad-process-inner cad-process-one col-6'>
                            <div className='cad-wwo-card cad-process-card'>
                                <div className='cad-proces-inner-block-icon'>
                                    <Link to="/jewelry-manual-designing">
                                        <video className='videoTag' autoPlay loop muted>
                                            <source src="assets/images/manual-course-video.mp4" type='video/mp4' />
                                        </video>
                                    </Link>
                                </div>
                                <div className='cad-process-inner-block'>
                                    <Link to="/jewelry-manual-designing">
                                        <h4>Professional Jewelry Manual Hand Designing Diploma</h4>
                                    </Link>
                                    <p>Experience the world of Jewelry Designing by enrolling in this course and explore the countless career options for yourself. With the help of a pencil and paper Manual Designing course enables you to create unique artistic designs out of your own imagination that perfectly suit your style.</p>
                                    <div className='cad-services-connect-btn'>
                                        <NavLink to='/jewelry-manual-designing'>Read More</NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='cad-process-inner cad-process-two col-6'>
                            <div className='cad-wwo-card cad-process-card'>
                                <div className='cad-proces-inner-block-icon'>
                                    <Link to="/jewelry-cad-designing">
                                        <video className='videoTag' autoPlay loop muted>
                                            <source src="assets/images/cad-course-video.mp4" type='video/mp4' />
                                        </video>
                                    </Link>
                                </div>
                                <div className='cad-process-inner-block'>
                                    <Link to="/jewelry-cad-designing"><h4>Jewelry 3D CAD Professional Designing Diploma</h4></Link>
                                    <p>Learn how to convert your amazing design ideas to 3-Dimensional Models so that they can be brought in the real world through 3D Printing. It doesn’t matter if you have an image, a sketch or even just a thought in your mind, this course unlocks your potential as 3D Artist, enables you to master art of 3D Modeling.</p>
                                    <div className='cad-services-connect-btn'>
                                        <NavLink to='/jewelry-cad-designing'>Read More</NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className='cad-services-section'>
            <div className='container'>
                <div className='row cad-services-align'>
                    <div className='cad-services-inner cad-about-section-right-inner cad-home-banner-inner col-6'>
                        <span>Services</span>
                        <h2>How We Can Help You</h2>
                        <p>Not sure where to start? We are here to help you at every step of your journey. We have more than 15 years of experience in the jewelry industry and are thorough with the complete manufacturing process. Our expertise in the field allows us to teach you all the real life practical aspects and parameters by which each and every piece should be designed.</p>
                        <p>At International Institute of CAD and Render, we are dedicated to providing you with comprehensive resources and training materials to help you master the art of jewelry design using Manual Hand sketching and drawing techniques and Computer-Aided Design (CAD) software training. Whether you're a beginner or an experienced designer looking to enhance your skills, we offer a wealth of knowledge to guide you on your journey. </p>
                        <div className='cad-services-inner-btn'>
                            <div className='cad-services-connect-btn cad-home-banner-btn'>
                                <NavLink to='/contact-us'>Connect Now</NavLink>
                            </div>
                            <div className='cad-services-learn-btn cad-home-banner-btn'>
                                <NavLink to='/jewelry-cad-designing'>Learn More</NavLink>
                            </div>
                        </div>
                    </div>
                    <div className='cad-services-image col-6'>
                        <div className='cad-services-image-inner'>
                            <Link to="/jewelry-cad-designing"><img src='/assets/images/how-can-we-help.jpg' /></Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className='cad-career-section cad-about-section'>
            <div className='container'>
                <div className='row cad-about-section-align'>                   
                    <div className='cad-about-section-left col-6'>
                        <div className='cad-career-img cad-about-instructor-img'>
                            <img src="/assets/images/inst.webp" />
                        </div>
                    </div>
                    <div className='cad-about-section-right col-6'>
                        <div className='cad-about-section-right-inner cad-home-banner-inner'>
                            <span>Career</span>
                            <h2>Career Options</h2>
                            <p>The jewelry industry is a vast and ever growing world whose hunger for new, artistic and unique designs never ends. Jewelers constantly look for skilled 2D and 3D designers who can always make amazing new designs for them, they are always in need of someone who can convert their ideas into practical and accurate designs and then make 3D models out of those for manufacturing purposes.</p>
                            <p>Career opportunities are never ending for both Manual Designers and CAD Designers, one cannot compensate the need of the other and the opportunities are even better if the person excels in both the skills. A Manual Designer is the one who lays the stepping stone in the Jewelry manufacturing process, he is the person who innovates the design out of his creativity and converts the idea into a beautiful design. A CAD Designer on the other hand is the one who converts those 2D Designs to 3D Models so they can be brought to reality through the means of 3D Printing process, his job the next immediate process after the manual designing is done.</p>
                            <p>We at IICR also offer interviews and job placements to well established organisations so you don’t have to worry about getting a kick start to your career after your internship period ends.</p>
                        </div> 
                    </div>
                </div>
            </div>
        </div>
        
        <div className='cad-contact-form'>
            <div className='container'>
                <div className='row cad-contact-form-align'>
                    <ContactForm />
                    <div className='cad-contact-form-image col-6'>
                        <div className='cad-contact-form-image-inner'>
                            <Link to="/contact-us"><img src='/assets/images/contact-form-home.jpg' /></Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <CadHomeGallery />
       
        <div className='cad-process'>
            <div className='container'>
                <div className='cad-process-header cad-about-section-right-inner cad-home-banner-inner'>
                    <span>Process</span>
                    <h2>Our Process</h2>
                    <p className='process-cad-mini-title'>Here's an overview of what you can expect from us:</p>
                    <div className='row cad-process-align'>
                        <div className='cad-process-inner cad-process-one col-3'>
                            <div className='cad-process-card'>
                                <div className='cad-proces-inner-block-icon'>
                                    <img src="/assets/images/process-1.jpg" />
                                </div>
                                <div className='cad-process-inner-block'>
                                    <h4>Introduction to Jewelry Designing</h4>
                                    <ul>
                                        <li>Understanding the basics jewelry design.</li>
                                        <li>Exploring different designing methods and their features.</li>
                                        <li>Getting familiar with the tools and software used.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className='cad-process-inner cad-process-two col-3'>
                            <div className='cad-process-card'>
                                <div className='cad-proces-inner-block-icon'>
                                    <img src="/assets/images/process-2.jpg" />
                                </div>
                                <div className='cad-process-inner-block'>
                                    <h4>Jewelry Design Fundamentals</h4>
                                    <ul>
                                        <li>Learning essential terminology and concepts related to jewelry design.</li>
                                        <li>Understanding the anatomy of a jewelry piece.</li>
                                        <li>Creating precise measurements, angles, and curves.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className='cad-process-inner cad-process-four col-3'>
                            <div className='cad-process-card'>
                                <div className='cad-proces-inner-block-icon'>
                                    <img src="/assets/images/process-6.jpg" />
                                </div>
                                <div className='cad-process-inner-block'>
                                    <h4>2D Modeling Techniques</h4>
                                    <ul>
                                        <li>Learning how to convert your ideas to practical jewelry designs using pencils and paper.</li>
                                        <li>Making new and uniquReceiving feedback and e designs that correspond to the tastes and requirements.</li>
                                        <li>Creating 2D sketches that are technically and practically accurate.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className='cad-process-inner cad-process-three col-3'>
                            <div className='cad-process-card'>
                                <div className='cad-proces-inner-block-icon'>
                                    <img src="/assets/images/process-3.jpg" />
                                </div>
                                <div className='cad-process-inner-block'>
                                    <h4>3D Modeling Techniques</h4>
                                    <ul>
                                        <li>Transforming 2D sketches or images to 3D Models.</li>
                                        <li>Exploring different methods of creating complex geometries, including sweeping, lofting, and Boolean operations.</li>
                                        <li>Incorporating fillets, chamfers, and other design details.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className='cad-process-inner cad-process-four col-3'>
                            <div className='cad-process-card'>
                                <div className='cad-proces-inner-block-icon'>
                                    <img src="/assets/images/process-4.jpg" />
                                </div>
                                <div className='cad-process-inner-block'>
                                    <h4>Gemstone and Setting Design</h4>
                                    <ul>
                                        <li>Understanding the intricacies of designing settings for gemstones.</li>
                                        <li>Learning about different stone cuts, settings, and prong configurations.</li>
                                        <li>Applying techniques to create realistic and secure stone settings.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className='cad-process-inner cad-process-one col-3'>
                            <div className='cad-process-card'>
                                <div className='cad-proces-inner-block-icon'>
                                    <img src="/assets/images/process-5.jpg" />
                                </div>
                                <div className='cad-process-inner-block'>
                                    <h4>Creating Custom Designs</h4>
                                    <ul>
                                        <li>Learning advanced techniques to bring your unique jewelry ideas to life.</li>
                                        <li>Incorporating personalized elements, such as initials or symbols, into your designs. </li>
                                        <li>Understanding design constraints and technical considerations for manufacturing.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className='cad-process-inner cad-process-two col-3'>
                            <div className='cad-process-card'>
                                <div className='cad-proces-inner-block-icon'>
                                    <img src="/assets/images/process-6.webp" />
                                </div>
                                <div className='cad-process-inner-block'>
                                    <h4>Advanced Features & Functionalities</h4>
                                    <ul>
                                        <li>Exploring advanced CAD tools and functionalities specific to jewelry design.</li>
                                        <li>Working with curves, splines, and organic shapes.</li>
                                        <li>Incorporating engraving, filigree, and other decorative elements.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className='cad-process-inner cad-process-three col-3'>
                            <div className='cad-process-card'>
                                <div className='cad-proces-inner-block-icon'>
                                    <img src="/assets/images/process-8.webp" />
                                </div>
                                <div className='cad-process-inner-block'>
                                    <h4>Design Validation and Analysis</h4>
                                    <ul>
                                        <li>Learning how to validate your designs for manufacturing feasibility. </li>
                                        <li>Applying dimensions and constraints to ensure accurate designs. </li>
                                        <li>Addressing potential manufacturing issues and optimizing designs for production.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className='cad-process-inner cad-process-four col-3'>
                            <div className='cad-process-card'>
                                <div className='cad-proces-inner-block-icon'>
                                    <img src="/assets/images/process-7.webp" />
                                </div>
                                <div className='cad-process-inner-block'>
                                    <h4>Rendering and Presentation</h4>
                                    <ul>
                                        <li>Enhancing your designs with realistic rendering techniques.</li>
                                        <li>Exploring materials, lighting, and environment settings.</li>
                                        <li>Creating captivating visual presentations and showcasing your jewelry designs.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className='cad-process-inner cad-process-three col-3'>
                            <div className='cad-process-card'>
                                <div className='cad-proces-inner-block-icon'>
                                    <img src="/assets/images/process-9.jpg" />
                                </div>
                                <div className='cad-process-inner-block'>
                                    <h4>Industry Insights and Trends</h4>
                                    <ul>
                                        <li>Staying up-to-date with the latest trends and developments in the jewelry industry.</li>
                                        <li>Understanding market demands and consumer preferences.</li>
                                        <li>Exploring case studies and success stories of renowned jewelry designers.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className='cad-process-inner cad-process-four col-3'>
                            <div className='cad-process-card'>
                                <div className='cad-proces-inner-block-icon'>
                                    <img src="/assets/images/process-10.jpg" />
                                </div>
                                <div className='cad-process-inner-block'>
                                    <h4>Practical Projects and Exercises</h4>
                                    <ul>
                                        <li>Engaging in hands-on exercises and projects to apply your learning.</li>
                                        <li>Creating custom jewelry pieces from scratch.</li>
                                        <li>Receiving feedback and guidance from experts in the field.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        
                        <div className='cad-process-inner cad-process-four cad-process-last col-3'>
                            <div className='cad-process-card'>
                                <div className='cad-process-inner-block cad-process-lst-block'>
                                    <div className='cad-process-lst-blck-inner'>
                                        <h4>Ready to embark on Your Jewelry CAD Journey?</h4>
                                        <p>Whether you aspire to become a professional jewelry designer or simply want to explore your creative side, we are committed to providing a comprehensive learning experience that empowers you to create stunning jewelry designs using CAD software. Join us today and unlock your full potential as a jewelry CAD designer!</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='cad-services-connect-btn'>
                        <NavLink to='/jewelry-manual-designing'>Explore Courses</NavLink>
                    </div>
                </div>
            </div>
        </div>

        <div className='cad-testimonials'>
            <div className='container'>
                <div className='cad-testimonials-header cad-about-section-right-inner cad-home-banner-inner'>
                    <span>Testimonials</span>
                    <h2>What People Say About Us</h2>
                </div>
                <div className='row cad-testimonials-align'>
                    <Testimonials />
                </div>
            </div>
        </div>
        <Footer />
        </>
    )
}

export default Home;