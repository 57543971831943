import React from 'react';
import {BrowserRouter as Router ,Route,Routes} from 'react-router-dom';


// pages imported

import Home from './components/Home';
import About from './components/About';
import CourseOffline from './components/CourseOffline';
import CourseOnline from './components/CourseOnline';
// import Blogs from './components/Blogs';
// import BlogSingle from './components/BlogSingle';
// import Events from './components/Events';
// import EventSingle from './components/EventSingle';
import FAQ from './components/FAQ';
import Contact from './components/Contact';
import TermsConditions from './components/TermsConditions';
import NotFound from './components/NotFound';


const App = () => {
  return (
    <>
    <Router>
      <Routes>
          <Route path="/" element={<Home />} ></Route>
          <Route path="/about-us" element={<About />} ></Route>
          <Route path="/jewelry-manual-designing" element={<CourseOffline />} ></Route>
          <Route path="/jewelry-cad-designing" element={<CourseOnline />} ></Route>
          {/* <Route path="/blog" element={<Blogs />} ></Route>
          <Route path="/blog/:ID" element={<BlogSingle />} ></Route> */}
          {/* <Route path="/events" element={<Events />} ></Route>
          <Route path="/events/:ID" element={<EventSingle />} ></Route> */}
          <Route path="/faqs" element={<FAQ />} ></Route>
          <Route path="/contact-us" element={<Contact />} ></Route>
          <Route path="/terms&conditions" element={<TermsConditions />} ></Route>
          <Route path="/*" element={<NotFound />} ></Route>
      </Routes>
    </Router>
    </>
  );
}

export default App;
