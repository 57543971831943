import React, {useState, useEffect} from 'react';
import axios from 'axios';



const Accordians = () => {
    const [openIndex, setOpenIndex] = useState(0); 

    const[faq,setFaq]= useState([]);
    
    useEffect(()=>{
      axios.get("https://www.iicr.co.in/my-admin/fetch-faqs.php").then((response)=>{
      setFaq(response.data.faqsresult);
    })
    },[]);

  const handleAccordionToggle = (index) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? -1 : index));
  };
    return(
        <>
        <div className="cad-faq-accordian">
      {faq.map((item, index) => (
        <div
          key={index}
          className={`cad-faq-accordian-item ${openIndex === index ? 'active' : ''}`}
        >
          <button
            className="cad-faq-accordian-question"
            onClick={() => handleAccordionToggle(index)}
          >
            {item.question}
          </button>
          <div
            className={`cad-faq-accordian-answer ${openIndex === index ? 'active' : ''}`}
          >
            {item.answer}
          </div>
        </div>
      ))}
    </div>
        </>
    )
}


export default Accordians;