import React, {useState, useEffect} from "react";
import Slider from "react-slick";
import axios from "axios";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const Testimonials = () => {

    const[testimo,setTestimo]= useState([]);
    useEffect(()=>{
    axios.get("https://www.iicr.co.in/my-admin/fetch-testimonials.php").then((response)=>{
        setTestimo(response.data.testimonialsresult);
    })
    },[]);

    var settings = {

        dots: false,
        infinite: true,
        arrows: true,
        speed: 500,
        autoplay: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        InitalSlide : 0,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
              infinite: true,
              dots: false
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            }
          }
        ]
      };

    return(
        <>
        <div className="cad-testimonials-slider col-12">
            <Slider {...settings}>
            {
        testimo.map((tst,index)=>{
                    return(
                <div className="cad-testmonials-slider-inner col-4">
                    <div className="cad-testimonials-inner">
                        <div className="cad-testimonials-ratndmsg">
                            <div className="cad-testimonials-rating">
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                            </div>
                            <p className="cad-testimonials-message">{tst.message}</p>
                            <i className="fa fa-quote-left"></i>
                        </div>    
                        <div className="cad-testimonials-ic-align">
                            <div className="cad-testimonials-image">
                                <img src={`/assets/images/${tst.image}`} />
                            </div>
                            <div className="cad-testimonials-content">
                                <h5>{tst.name}</h5>
                                <p>{tst.country}</p>
                            </div>
                        </div>
                    </div>
                </div>
                )
            })
          }
            </Slider>
        </div>
        </>
    )
}


export default Testimonials;