import React, {useState, useEffect} from 'react';
import { NavLink } from "react-router-dom";
import axios from 'axios';

import SocialMedia from './SocialMedia';

const Footer  = () => {
    const currentYear = new Date().getFullYear();

    const [confooter, setConfooter] = useState([]);

    useEffect(()=>{
        axios.get("https://www.iicr.co.in/my-admin/fetch-contact.php").then((response)=>{
            setConfooter(response.data.contactresult);
      })
    },[]);

    const [count, setCount] = useState(0);
  const targetValue = 4800;
  const duration = 2000; 

  const increment = (targetValue / duration) * 10;

  useEffect(() => {
    let currentCount = 0;

    const timer = setInterval(() => {
      if (currentCount < targetValue) {
        currentCount += increment;
        setCount(Math.min(currentCount, targetValue));
      }
    }, 10);

    return () => {
      clearInterval(timer);
    };
  }, [targetValue, duration]);

    return(
        <>
        <div className='cad-footer-main'>
            <div className='container'>
                <div className='cad-footer-projects'>
                <h5>Projects Completed :  <span>{count.toFixed(0)}+</span></h5>
                </div>
                <div className='row cad-footer-align'>
                    <div className='cad-footer-one cad-footer-logo-social col-4'>
                        <div className='cad-footer-logo'>
                            <NavLink to='/'><img src='/assets/images/render-logo.webp' /></NavLink>
                        </div>
                        
                        <div className='cad-footer-one-social'>
                            <h5>Follow Us</h5>
                            <SocialMedia />
                        </div>
                    </div>
                    <div className='cad-footer-one cad-footer-company col-4'>
                        <div className='cad-footer-one-social cad-footer-one-pages'>
                            <div className='cad-footer-one-pages-inner'>
                                <h5>Company</h5>
                                <ul>
                                    <li><NavLink to='/' activeClassName='active'>Home</NavLink></li>
                                    <li><NavLink to='/about-us' activeClassName='active'>About</NavLink></li>
                                    <li><NavLink to="/jewelry-manual-designing" activeClassName='active'>Jewelry Manual Designing Diploma</NavLink></li>
                                    <li><NavLink to="/jewelry-cad-designing" activeClassName='active'>Jewelry CAD Designing Diploma</NavLink></li>
                                    {/* <li><NavLink to='/blog' activeClassName='active'>Blog</NavLink></li>
                                    <li><NavLink to='/events' activeClassName='active'>Events</NavLink></li> */}
                                    <li><NavLink to='/faqs' activeClassName='active'>FAQ'S</NavLink></li>
                                    <li><NavLink to='/contact-us' activeClassName='active'>Contact</NavLink></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className='cad-footer-one cad-footer-contact col-4'>
                        <div className='cad-footer-one-social cad-footer-one-pages cad-footer-one-get-in-touch'>
                            <h5>Get In Touch</h5>
                            <ul>
                            {confooter.map((res)=>
                            <>
                                <li><i className='fa fa-phone'></i><a href={`tel: ${res.calling}`}>{res.calling}</a></li>
                                <li><i className='fa fa-envelope'></i><a href={`mailto: ${res.email}`}>{res.email}</a></li>
                                <li><i className='fa fa-map-marker'></i><a href={`${res.map_url}`} target='_blank'>{res.address}</a></li>
                            </>
                            )}
                            </ul>
                        </div>  
                    </div>
                </div>
                <div className='cad-footer-btm-inner'>
                    <div className='cad-footer-btm-text'>
                        <p>© {currentYear} <strong>Jaipur Gems and Jewels </strong>Company, All Rights Reserved.</p>
                    </div>
                    <div className='cad-footer-btm-links'>
                        <p><NavLink to='/terms&conditions'>Terms & Conditions</NavLink></p>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}


export default Footer;