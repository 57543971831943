import React , { useEffect } from 'react';
import { NavLink } from 'react-router-dom';

import Header from './Header';
import Footer from './Footer';
import Title from "./Title";
import ScrollTop from './ScrollTop';
import ContactIcon from './ContactIcon';
import WhatsAppChat from './WhatsAppChat';
import CookiesPopup from './CookiesPopup';

const NotFound = () => {

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant"
        });
      }, []);

    return(
        <>
        <Title title='Not Found | Cad And Render' />
        <ScrollTop />
        <ContactIcon />
        <WhatsAppChat />
        <CookiesPopup />
        <Header />
        <div className='cad-notfound-banner cad-home-banner'>
            <img src="/assets/images/not-found-banner.webp" />
            <div className='container clearfix'>
                <div className='cad-home-banner-inner col-12'>
                    <h1>Page Not Found</h1>
                </div>
            </div>
        </div>
        <div className='cad-not-found cad-thank-you'>
            <div className='container'>
                <div className='cad-testimonials-header cad-about-section-right-inner cad-home-banner-inner'>
                    <h4>Page Not Found</h4>
                    <div className='cad-services-connect-btn'>
                        <NavLink to='/'>Go To Home</NavLink>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
        </>
    )
}

export default NotFound;