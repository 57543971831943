import React, {useState, useRef} from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import axios from 'axios';
import {useNavigate} from 'react-router-dom';

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';


const ContactForm = () => {

    const [showPopup, setShowPopup] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [phone, setPhone] = useState('');

    const recaptchaRef = useRef(null);

    const Navigate = useNavigate();

    const [nameError, setNameError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [messageError, setMessageError] = useState('');
    const [phoneError, setPhoneError] = useState('');
    const [catchaError, setCatchaError] = useState('');

    const [isSubmit, setIsSubmit] = useState(false);

    const [verified, setVerified] = useState(false);

    const popupClose = () =>{
      setShowPopup(false);
    }


    const handleSubmit = async (e) => {
        e.preventDefault();

        const formdata = {
          'name': name,
          'email': email,
          'phone': phone,
          'message': message
        }

        if (formIsValid()) {

          setIsLoading(true);
          document.body.style.overflow = 'hidden';

          try{
            const response = await axios.post("https://www.iicr.co.in/my-admin/form-data-send.php",{formdata});
            if(response.status === 200){
              setName('');
              setEmail('');
              setPhone('');
              setMessage('');
              recaptchaRef.current.reset();
              setIsSubmit(true);
              setVerified(false);
              setShowPopup(true);
            }
          }catch(error){
            console.error(error);
          }finally {
            setIsLoading(false); // Hide loading gif
          }
      }
    };



    // form change start
    const handleNameChange = (e) => {
      const newName = e.target.value;
      setName(newName);

      if (!isValidName(newName)) {
          setNameError('Please enter characters only*');
      } else {
          setNameError('');
      }
  };

  const handleEmailChange = (e) => {
      const newEmail = e.target.value;
      setEmail(newEmail);

      if (!isValidEmail(newEmail)) {
          setEmailError('Please enter a valid email address*');
      } else {
          setEmailError('');
      }
  };

  const handlePhoneChange = (value, country) => {
    setPhone(value);
    setPhoneError(isValidPhone(value, country));
};

  const handleMessageChange = (e) => {
      const newMessage = e.target.value;
      setMessage(newMessage);

      if (newMessage.trim() === '') {
          setMessageError('Message is required*');
      } else {
          setMessageError('');
      }
  };

  const handleCaptchaChange = (value) => {
    setVerified(true);
    setCatchaError('');
};
  // form change end

    const formIsValid = () => {
        let isValid = true;
    
        if (name.trim() === '') {
          setNameError('*Name is required!');
          isValid = false;
        } else if(!isValidName(name)){
            setNameError('Please enter characters only*');
        } else {
          setNameError('');
        }
    
        if (email.trim() === '') {
          setEmailError('Email is required*');
          isValid = false;
        } else if (!isValidEmail(email)) {
          setEmailError('Please enter valid email address*');
          isValid = false;
        } else {
          setEmailError('');
        }

        if (phone.trim() === '') {
          setPhoneError('*Please enter valid contact number!');
          isValid = false;
        } else if (!isValidPhone(phone)) {
          setPhoneError('Please enter valid contact number!');
          isValid = false;
        } else {
          setPhoneError('');
        }
    
        if (message.trim() === '') {
          setMessageError('Message is required*');
          isValid = false;
        } else {
          setMessageError('');
        }

        if(!verified){
          setCatchaError('Please verify the recaptcha*');
          isValid = false;
        }else{
          setCatchaError('');
        }

        return isValid;
      };

      const isValidName = (name) => {
        return /^[a-zA-Z ]+$/i.test(name);
      };

      const isValidEmail = (email) => {
        return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/i.test(email);
      };

      const isValidPhone = (phone, country) => {
        if (country && country.countryCode === 'in') {
          // For India, consider both 10-digit and 13-digit formats (with country code)
          return /^(\+91)?[0-9]{10}$|^([+]\d{2})?\d{13}$/.test(phone);
        } else {
          // For other countries, allow a maximum of 15 digits
          return /^([+]\d{2})?\d{1,15}$/.test(phone);
        }
      };

    return(
        <>
        <div className='cad-contact-form-fields col-6'>
            <div className='cad-contact-form-form cad-about-section-right-inner cad-home-banner-inner'>
                <span>Get In Touch</span>
                <h2>Start Learning Now</h2>
                {showPopup && (
                  <div className="overlay">
                    <div className="popup">
                      <div className='popup-check-right'>
                        <i className='fa fa-check'></i>
                      </div>
                      <h3>Thank You for reaching out to us.</h3>
                      <p>Your request has been submitted, we will get back to you very soon.</p>
                      <div className='overlay-close-btn'>
                        <button onClick={popupClose}>Ok</button>
                      </div>
                    </div>
                  </div>
                )}
                <form method='POST' onSubmit={handleSubmit}>
                    <div className="inputtypes">
                        <input type="text" name="name" placeholder="Name" className="namebox user-name" value={name} 
                        onChange={handleNameChange} />
                        <i className='fa fa-user-o'></i>
                        <span className="formErrorsShow">{nameError}</span>
                    </div>
                    <div className="inputtypes">
                        <input type="text" name="email" placeholder="Email" className="namebox user-email" value={email} 
                        onChange={handleEmailChange} />
                        <i className='fa fa-envelope-o'></i>
                        <span className="formErrorsShow">{emailError}</span>
                    </div>
                    <div className="inputtypes">
                      <PhoneInput country={'in'} name="phone" placeholder="Contact Number" className="namebox" value={phone} 
                    onChange={handlePhoneChange} />
                        <span className="formErrorsShow">{phoneError}</span>
                    </div>
                    <div className="inputtypes input-msg">
                        <textarea type="text" rows="7" name="message" placeholder="Message" className="namebox user-message" value={message} 
                        onChange={handleMessageChange} ></textarea>
                        <i className='fa fa-pencil pencil-icon'></i>
                        <span className="formErrorsShow">{messageError}</span>
                    </div>
                    <div className='inputtypes'>
                        <ReCAPTCHA
                        sitekey="6LdEjm0oAAAAACrrXqaR2FsZdu_zhP5fg1gR2zsQ"
                        // sitekey='6LcfUJYlAAAAAMZKLnki7CIqzNT2CjtmcNaLYfKa'
                        ref={recaptchaRef}
                        onChange={handleCaptchaChange}
                    />
                    <span className="formErrorsShow">{catchaError}</span>
                    </div>
                    <div className='submitform'>
                        <button type="submit" className='submit'>Submit</button>
                    </div>
                </form>
            </div>
        </div>  
        {isLoading && (
        <div className='loading-overlay'>
            <div className="loading-spinner">
                <img src="/assets/images/loading.gif" alt="Loading..." />
            </div>
        </div>
        )}         
        </>
    )
}

export default ContactForm;

