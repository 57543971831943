import React , { useEffect } from 'react';
import { Link } from 'react-router-dom';

import Header from './Header';
import Footer from './Footer';
import Title from "./Title";
import ScrollTop from './ScrollTop';
import ContactIcon from './ContactIcon';
import ContactForm from './ContactForm';
import WhatsAppChat from './WhatsAppChat';
import CookiesPopup from './CookiesPopup';
import CadDesigningGallery from './CadDesigningGallery';

const CourseOnline = () => {

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant"
        });
      }, []);

    return(
        <>
        <Title title='Jewelry CAD Certification ( Offline ) | Cad And Render' />
        <ScrollTop />
        <ContactIcon />
        <WhatsAppChat />
        <CookiesPopup />
        <Header />
        <div className='cad-courses-banner cad-home-banner'>
            <video className='videoTag' autoPlay loop muted>
                <source src="assets/images/cad-course-video.mp4" type='video/mp4' />
            </video>
            {/* <div className='container clearfix'>
                <div className='cad-home-banner-inner col-12'>
                    <h1>Jewelry CAD Certification</h1>
                </div>
            </div> */}
        </div>
        <div className='cad-courses-main cad-about-placement-learning'>
            <div className='container'>
                <div className='cad-about-placement-learning-inner cad-about-section-right-inner cad-home-banner-inner'>
                    <span>Courses</span>
                    <h2>Jewelry CAD Certification</h2>
                </div>
                <div className='row cad-courses-inner-list'>
                    <div className='cad-courses-inner-right col-6'>
                        <div className='cad-courses-inner-right-img'>
                            <img src='/assets/images/cad-jewelry.webp' />
                        </div>
                    </div>
                    <div className='cad-courses-inner-left col-6'>
                        <div className='cad-courses-inner-left-list'>
                            <p>This course is perfect for beginners who are new to jewelry CAD. It –</p>
                            <ul>
                                <li>Provides an overview of CAD software, introduces the basic tools and features, and familiarizes learners with the CAD interface.</li>
                                <li>Helps learn essential techniques for drawing, shaping, and manipulating jewelry components. Explore precise measurements, angles, and curves to create accurate designs.</li>
                                <li>Focuses on gemstone and diamond setting techniques using CAD software. Learn how to create prong, bezel, pave, and channel settings. Explore different stone arrangement strategies and create designs that showcase gemstones and diamonds effectively.</li>
                                <li>Lets you create custom jewelry designs using CAD software. Learn how to incorporate personalized elements, such as initials, symbols, or unique design motifs, into your jewelry pieces.</li>
                                <li>Helps enhance your jewelry designs by adding textures and surface finishes. It teaches techniques for creating realistic textures that leave an impression on the design, beautifying it even further.</li>
                                <li>Teaches you to prepare your jewelry designs for 3D Printing. Learn about considerations, file formats and optimization techniques for successful 3D printing of your jewelry pieces.</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <p className='cad-course-ext-pra'>Join IICR now and explore our comprehensive teaching methods designed to develop 	your skills, broaden your knowledge and unlock your creativity in the fascinating world of Jewelry CAD Designing.</p>
            </div>
        </div>

        <CadDesigningGallery />
        
        <div className='cad-contact-form'>
            <div className='container'>
                <div className='row cad-contact-form-align'>
                    <ContactForm />
                    <div className='cad-contact-form-image col-6'>
                        <div className='cad-contact-form-image-inner'>
                            <Link to="/contact-us"><img src='/assets/images/cad-designing-contact-form.jpg' /></Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <Footer />
        </>
    )
}

export default CourseOnline;