import React, { useState, useEffect } from 'react';
import axios from 'axios';

import ImageModal from './ImageModal';

function ManualDesigningGallery() {
  const [noOfElements, setNoOfElement] = useState(20);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);

  const loadMore = () => {
    setNoOfElement(noOfElements + 20);
  };

  const openImageModal = (index) => {
    setSelectedImageIndex(index);
  };

  const closeImageModal = () => {
    setSelectedImageIndex(null);
  };

  const[galleryImages,setGalleryImages]= useState({ mdresult: [] });
    
    useEffect(()=>{
      axios.get("https://www.iicr.co.in/my-admin/fetch-manual-designing-gallery.php").then((response)=>{
        setGalleryImages({mdresult:response.data.mdresult});
    })
    },[]);

  return (
    <>
      <div className="cad-course-gallery">
        <div className="container">
          <div className="row">
            {galleryImages.mdresult.slice(0, noOfElements).map((item, index) => {
              return (
                <div className="cad-course-blocks col-3" key={index}>
                  <div className="cad-course-gallery-img">
                    <img src={`/assets/images/${item.image}`} onClick={() => openImageModal(index)} />
                  </div>
                </div>
              );
            })}
          </div>
          <button
            style={{
              display:
                noOfElements >= galleryImages.mdresult.length
                  ? 'none'
                  : 'block',
            }}
            className="loadmoreGalleryBtn"
            onClick={() => loadMore()}
          >
            LOAD MORE
          </button>
        </div>
      </div>
      {selectedImageIndex !== null && (
        <ImageModal
          images={galleryImages.mdresult}
          selectedIndex={selectedImageIndex}
          onClose={closeImageModal}
        />
      )}
    </>
  );
}

export default ManualDesigningGallery;