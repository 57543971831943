import React, {useState, useEffect} from 'react';
import axios from 'axios';


const ContactIcon = () => {

    const [phoneicon, setPhoneicon] = useState([]);

    useEffect(()=>{
        axios.get("https://www.iicr.co.in/my-admin/fetch-contact.php").then((response)=>{
            setPhoneicon(response.data.contactresult);
      })
    },[]);

    return(
        <>
        <div className='ContactIcon'>
        {phoneicon.map((res)=>
        <>
            <a href={`tel: ${res.calling}`}><i className='fa fa-phone'></i></a>
        </>
        )}
        </div>
        </>
    );
}


export default ContactIcon;