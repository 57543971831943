import React, {useState, useEffect} from 'react';

const ScrollTop = () => {

    const [showButton, setShowButton] = useState(false);

  function handleScroll() {
    if (window.pageYOffset > 100) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  }

  function handleClick() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
  
    return(
       
        showButton && (
      <div className="ScrollTopBtn"><button className="scroll-to-top-button" onClick={handleClick}>
        <i className="fa fa-angle-up"></i>
      </button></div>
    )
        
    );
}

export default ScrollTop;