import React , { useEffect, useState } from 'react';
import axios from 'axios';

import Header from './Header';
import Footer from './Footer';
import Title from "./Title";
import ScrollTop from './ScrollTop';
import ContactForm from './ContactForm';
import ContactIcon from './ContactIcon';
import WhatsAppChat from './WhatsAppChat';
import CookiesPopup from './CookiesPopup';

const Contact = () => {

    const [livemap, setLivemap] = useState([]);

    useEffect(()=>{
        axios.get("https://www.iicr.co.in/my-admin/fetch-contact.php").then((response)=>{
            setLivemap(response.data.contactresult);
      })
    },[]);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant"
        });
      }, []);

    return(
        <>
        <Title title='Contact | Cad And Render' />
        <ScrollTop />
        <ContactIcon />
        <WhatsAppChat />
        <CookiesPopup />
        <Header />
        <div className='cad-contact-banner cad-home-banner'>
            <img src="/assets/images/contact-banner.webp" />
            <div className='container clearfix'>
                <div className='cad-home-banner-inner col-12'>
                    <h1>Get In Touch</h1>
                </div>
            </div>
        </div>
        <div className='cad-contact-form'>
            <div className='container'>
                <div className='row cad-contact-form-align'>
                    <ContactForm />
                    <div className='cad-contact-form-image col-6'>
                        <div className='cad-contact-form-image-inner'>
                            <img src='/assets/images/contact-us-page-form.jpg' />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        
        {livemap.map((res)=>
        <>
        <div className='cadContactMap'>
            <iframe src={`${res.iframe_url}`}></iframe>
        </div>
        </>
        )}
        <Footer />
        </>
    )
}

export default Contact;