import React , { useEffect } from 'react';
import { Link } from 'react-router-dom';

import Header from './Header';
import Footer from './Footer';
import Title from "./Title";
import ScrollTop from './ScrollTop';
import ContactIcon from './ContactIcon';
import ContactForm from './ContactForm';
import WhatsAppChat from './WhatsAppChat';
import Accordians from './Accordians';
import CookiesPopup from './CookiesPopup';

const FAQ = () => {

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant"
        });
      }, []);

    return(
        <>
        <Title title='FAQ | Cad And Render' />
        <ScrollTop />
        <ContactIcon />
        <WhatsAppChat />
        <CookiesPopup />
        <Header />
        <div className='cad-faq-banner cad-home-banner'>
            <img src="/assets/images/faq-banner.jpg" />
            <div className='container clearfix'>
                <div className='cad-home-banner-inner col-12'>
                    <h1>FAQ'S</h1>
                </div>
            </div>
        </div>
        <div className='cad-faq-main'>
            <div className='container'>
                <div className='cad-testimonials-header cad-about-section-right-inner cad-home-banner-inner'>
                    <span>FAQ'S</span>
                    <h2>Frequently Asked Questions</h2>
                </div>
                <Accordians />
            </div>
        </div>


        <div className='cad-contact-form'>
            <div className='container'>
                <div className='row cad-contact-form-align'>
                    <ContactForm />
                    <div className='cad-contact-form-image col-6'>
                        <div className='cad-contact-form-image-inner'>
                            <Link to="/contact-us"><img src='/assets/images/faq-contact-form.jpg' /></Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        
        <Footer />
        </>
    )
}

export default FAQ;