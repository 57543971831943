import React , { useEffect } from 'react';
import { Link } from 'react-router-dom';

import Header from './Header';
import Footer from './Footer';
import Title from "./Title";
import ScrollTop from './ScrollTop';
import ContactIcon from './ContactIcon';
import ContactForm from './ContactForm';
import WhatsAppChat from './WhatsAppChat';
import CookiesPopup from './CookiesPopup';
import ManualDesigningGallery from './ManualDesigningGallery';


const CourseOffline = () => {

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant"
        });
      }, []);

    return(
        <>
        <Title title='Jewelry CAD Certification ( Offline ) | Cad And Render' />
        <ScrollTop />
        <ContactIcon />
        <WhatsAppChat />
        <CookiesPopup />
        <Header />
        <div className='cad-courses-banner cad-home-banner'>
            <video className='videoTag' autoPlay loop muted>
                <source src="assets/images/manual-course-video.mp4" type='video/mp4' />
            </video>
            {/* <div className='container clearfix'>
                <div className='cad-home-banner-inner col-12'>
                    <h1>Manual Jewelry Designing </h1>
                </div>
            </div> */}
        </div>
        <div className='cad-courses-main cad-about-placement-learning'>
            <div className='container'>
                <div className='cad-about-placement-learning-inner cad-about-section-right-inner cad-home-banner-inner'>
                    <span>Courses</span>
                    <h2>Manual Jewelry Designing</h2>
                </div>
                <div className='row cad-courses-inner-list'>
                    <div className='cad-courses-inner-right col-6'>
                        <div className='cad-courses-inner-right-img'>
                            <img src='/assets/images/cad-manual.webp' />
                        </div>
                    </div>
                    <div className='cad-courses-inner-left col-6'>
                        <div className='cad-courses-inner-left-list'>
                            <p>This course marks the beginning of your Jewelry Designing journey. It –</p>
                            <ul>
                                <li>Helps you understand the different types of Jewelry (Rings, Earrings, etc.) and gather design inspiration from your environment to create new and unique designs.</li>
                                <li>Introduces you to the essential tools, different types of paper and other materials like gemstones, beads and different metals used to make jewelry designs.</li>
                                <li>Teaches you to draw basic shapes, line work and shading. It also trains you to design complex jewelry with multiple angles or layers and create 3-Dimensional effects in the design accurately.</li>
                                <li>Focuses on the study of jewelry design elements, such as symmetry, balance and proportion, it also enables you to analyse various jewelry styles so that you can add details like textures and intricate patterns to your design maintaining proper proportions so that the measurements in your design are exact and accurate.</li>
                                <li>Enables you in understanding various gemstone cuts and shapes, learning to sketch different gemstones and their facets while adding depth and sparkle to their sketches.</li>
                                <li>Lets you design specific jewelry item like rings, necklaces, earrings, etc. It also discusses the design considerations and variations you can input for each type of jewelry.</li>
                                <li>Trains you for creating mood boards to organize design ideas and develop a cohesive theme for a jewelry collection.</li>
                                <li>Guides you to add notes and labels to your sketches to describe materials, dimensions and other important design considerations.</li>
                                <li>Enables you to create your own customized designs which are technically and practically accurate so you won’t have to waste your time remaking the designs. </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <p className='cad-course-ext-pra'>Join with IICR now and get enrolled towards a bright Jewelry Designing future by the means 	of detailed learning, practical examples and real life situation dealing training programmes.</p>
            </div>
        </div>

        
        <ManualDesigningGallery />
        
        <div className='cad-contact-form'>
            <div className='container'>
                <div className='row cad-contact-form-align'>
                    <ContactForm />
                    <div className='cad-contact-form-image col-6'>
                        <div className='cad-contact-form-image-inner'>
                            <Link to="/contact-us"><img src='/assets/images/manual-design-contact-form.jpg' /></Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <Footer />
        </>
    )
}

export default CourseOffline;