import React, {useEffect, useState} from 'react';
import axios from 'axios';


const SocialMedia = () => {

    const[socialmedia,setSocialmedia]= useState([]);
    useEffect(()=>{
        axios.get("https://www.iicr.co.in/my-admin/fetch-socialmedia.php").then((response)=>{
            setSocialmedia(response.data.socialmediaresult);
        })
      },[]);

    return(
        <>
        <ul>
        {
            socialmedia.map ((scl,index)=>{
                return(
                    <>
                        <li><a href={scl.social_link} target='_blank'><i className={scl.social_icon}></i></a></li>
                    </>
                )
            })
        }
        </ul>
        </>
    )
}


export default SocialMedia;