import React, {useState, useEffect} from 'react';
import { NavLink } from "react-router-dom";

const Header = () => {

    const [toggle, setToggle] = useState(false);

    const [scrollClass, setScrollClass] = useState('');

    useEffect(() => {
        const handleScroll = () => {
          if (window.scrollY > 0) {
            setScrollClass('sticky');
          } else {
            setScrollClass('');
          }
        };
    
        window.addEventListener('scroll', handleScroll);
    
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);

      const handleMenuClick = () => {
        setToggle(!toggle);
      };

    return(
        <>
        <div className={`cad-main-header ${scrollClass}`}>
            <div className='container'>
                <div className='row cad-mid-header-align'>
                    <div className='cad-mid-header-left col-2'>
                        <div className='cad-mid-header-logo'>
                            <NavLink to='/'><img src='/assets/images/render-logo-black.webp' /></NavLink>
                        </div>
                    </div>
                    <div className='cad-mid-header-navbar col-10'>
                        <ul id='pcNav'>
                            <li><NavLink to='/' activeClassName='active'>HOME</NavLink></li>
                            <li><NavLink to='/about-us' activeClassName='active'>ABOUT</NavLink></li>
                            <li className='dropdown'><a href="#" className='dropbtn' activeClassName="active">COURSES <i className="fa fa-angle-down"></i></a>
                                <div className="dropdown-content">
                                    <ul>
                                        <li><NavLink to="/jewelry-manual-designing" activeClassName='active'>JEWELRY MANUAL DESIGNING DIPLOMA</NavLink></li>
                                        <li><NavLink to="/jewelry-cad-designing" activeClassName='active'>JEWELRY CAD DESIGNING DIPLOMA</NavLink></li>
                                    </ul>
                                </div>
                            </li>
                            {/* <li><NavLink to='/blog' activeClassName='active'>BLOG</NavLink></li>
                            <li><NavLink to='/events' activeClassName='active'>EVENTS</NavLink></li> */}
                            <li><NavLink to='/faqs' activeClassName='active'>FAQ</NavLink></li>
                            <li><NavLink to='/contact-us' activeClassName='active'>CONTACT</NavLink></li>
                        </ul>
                        <div className='mobileMneuToggleBtn'>
                            <div className="mobileMenuIcon" onClick={handleMenuClick}>&#9776;</div>
                            
                            <div className={toggle ? "mobileNavSidebar mobileNavSidebarOpen":"mobileNavSidebar"}>
                                <div className="mobileNavSidebarClose" onClick={() => setToggle(!toggle)}>&#x2715;</div>
                                <ul id="mobileNavBar">
                                    <li><NavLink to='/' activeClassName='active'>HOME</NavLink></li>
                                    <li><NavLink to='/about-us' activeClassName='active'>ABOUT</NavLink></li>
                                    <li className='dropdown'><a href="#" className='dropbtn' activeClassName="active">COURSES <i className="fa fa-angle-down"></i></a>
                                        <div className="dropdown-content">
                                            <ul>
                                                <li><NavLink to="/jewelry-manual-designing" activeClassName='active'>JEWELRY MANUAL DESIGNING DIPLOMA</NavLink></li>
                                                <li><NavLink to="/jewelry-cad-designing" activeClassName='active'>JEWELRY CAD DESIGNING DIPLOMA</NavLink></li>
                                            </ul>
                                        </div>
                                    </li>
                                    {/* <li><NavLink to='/blog' activeClassName='active'>BLOG</NavLink></li>
                                    <li><NavLink to='/events' activeClassName='active'>EVENTS</NavLink></li> */}
                                    <li><NavLink to='/faqs' activeClassName='active'>FAQ'S</NavLink></li>
                                    <li><NavLink to='/contact-us' activeClassName='active'>CONTACT</NavLink></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}


export default Header;