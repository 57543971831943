import React , { useEffect } from 'react';
import { Link } from 'react-router-dom';

import Header from './Header';
import Footer from './Footer';
import Title from "./Title";
import ScrollTop from './ScrollTop';
import ContactIcon from './ContactIcon';
import ContactForm from './ContactForm';
import WhatsAppChat from './WhatsAppChat';
import CookiesPopup from './CookiesPopup';
import Tabs from './Tabs';
import Testimonials from './Testimonials';

const About = () => {

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant"
        });
      }, []);

    return(
        <>
        <Title title='About | Cad And Render' />
        <ScrollTop />
        <ContactIcon />
        <WhatsAppChat />
        <CookiesPopup />
        <Header />
        <div className='cad-about-banner cad-home-banner'>
            <img src="/assets/images/about-banner.webp" />
            <div className='container clearfix'>
                <div className='cad-home-banner-inner col-12'>
                    <h1>About Us</h1>
                </div>
            </div>
        </div>

        <div className='cad-about-experience cad-about-ext cad-about-details cad-about-intro'>
            <div className='container'>
                <p className='cad-about-intro-text'>At CAD and Render, we are passionate about empowering individuals to unleash their creativity and master the art of jewelry design using Computer-Aided Design (CAD) technology. We are dedicated towards providing comprehensive learning that helps you master the skills and knowledge you need to excel in the jewelry industry.</p>
                <h2 className='cad-sets-apart-title'>What Sets Us Apart?</h2>
            </div>
        </div>

        <div className='cad-about-details'>
            <div className='container'>
                <div className='row cad-about-details-align'>
                    <div className='cad-about-details-left col-6'>
                        <div className='cad-about-intro-inner-img msn-img'>
                            <img src="/assets/images/mission.webp" />
                        </div>
                    </div>
                    <div className='cad-about-details-right right-first col-6'>
                        <div className='cad-about-section-right-inner cad-home-banner-inner'>
                            <span>Legation</span>
                            <h2>Our Mission</h2>
                            <p>Our mission is to equip aspiring jewelry designers, professionals, and enthusiasts with the knowledge, skills, and tools they need to thrive in the ever-evolving jewelry industry. We strive to foster a learning environment that promotes innovation, creativity, and technical expertise. Our goal is to empower individuals to bring their unique jewelry designs to life using CAD software and make a lasting impact in the field.</p>
                        </div> 
                    </div>
                </div>
            </div>
        </div>
        <div className='cad-about-experience cad-about-details'>
            <div className='container'>
                <div className='row cad-about-details-align reverse'>
                    <div className='cad-about-details-right right-second col-6'>
                        <div className='cad-about-section-right-inner cad-home-banner-inner'>
                            <span>Statesman</span>
                            <h2>Experienced Instructors </h2>
                            <p>At CAD and Render, we have assembled a team of experienced instructors who are not only skilled jewelry designers but also experts in CAD software. They bring a wealth of industry knowledge and practical experience to guide you through every aspect of the jewelry design process. Our instructors are dedicated to providing clear, concise, and engaging instruction that caters to learners of all levels, from beginners to advanced users.</p>
                        </div> 
                    </div>
                    <div className='cad-about-details-left col-6'>
                        <div className='cad-about-experience-img exp-img'>
                            <img src="/assets/images/experienced-inst.webp" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className='cad-about-challenges cad-about-details'>
            <div className='container'>
                <div className='row cad-about-details-align'>
                    <div className='cad-about-details-left col-6'>
                        <div className='cad-about-intro-inner-img chl-img'>
                            <img src="/assets/images/hand-challange.webp" />
                        </div>
                    </div>
                    <div className='cad-about-details-right right-third col-6'>
                        <div className='cad-about-section-right-inner cad-home-banner-inner'>
                            <span>Crawl</span>
                            <h2>Hands-On Projects and Challenges</h2>
                            <p>We believe that practical application is vital for mastery. That's why we provide hands-on projects and design challenges that allow you to put your CAD skills to the test. Engage in real-world scenarios, tackle design problems, and develop innovative solutions. Our projects and challenges are designed to enhance your problem-solving abilities, foster creativity, and build your portfolio of exceptional jewelry designs.</p>
                        </div> 
                    </div>
                </div>
            </div>
        </div>
        <div className='cad-about-experience cad-about-details'>
            <div className='container'>
                <div className='row cad-about-details-align reverse'>
                    <div className='cad-about-details-right right-four col-6'>
                        <div className='cad-about-section-right-inner cad-home-banner-inner'>
                            <span>Communal</span>
                            <h2>Supportive Community</h2>
                            <p>At CAD and Render, we value the power of community. Connect with a supportive network of fellow learners, designers, and industry professionals. Share your work, seek feedback, and collaborate with like-minded individuals who share your passion for jewelry design. Our community is a place where you can grow, learn, and be inspired by others' achievements. </p>
                        </div> 
                    </div>
                    <div className='cad-about-details-left col-6'>
                        <div className='cad-about-experience-img sprt-img'>
                            <img src="/assets/images/supportive-comm.webp" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className='cad-about-stay-ahead cad-about-challenges cad-about-details'>
            <div className='container'>
                <div className='row cad-about-details-align'>
                    <div className='cad-about-details-left col-6'>
                        <div className='cad-about-intro-inner-img crv-img'>
                            <img src="/assets/images/stay-curve.webp" />
                        </div>
                    </div>
                    <div className='cad-about-details-right right-five col-6'>
                        <div className='cad-about-section-right-inner cad-home-banner-inner'>
                            <span>Extrados</span>
                            <h2>Stay Ahead of the Curve</h2>
                            <p>The jewelry industry is constantly evolving, embracing new technologies, materials, and design trends. We ensure that you stay up-to-date with the latest industry insights, emerging technologies, and design trends. Our community keeps you informed about the ever-changing landscape of jewelry design, equipping you with the knowledge to create innovative and marketable designs.</p>
                        </div> 
                    </div>
                </div>
            </div>
        </div>

        <div className='cad-contact-form'>
            <div className='container'>
                <div className='row cad-contact-form-align'>
                    <ContactForm />
                    <div className='cad-contact-form-image col-6'>
                        <div className='cad-contact-form-image-inner'>
                            <Link to="/contact-us"><img src='/assets/images/about-us-contact-form.jpg' /></Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className='cad-about-placement-learning'>
            <div className='container'>
                <div className='cad-about-placement-learning-inner cad-about-section-right-inner cad-home-banner-inner'>
                    <h2>Placements</h2>
                    <Tabs />
                </div>
            </div>
        </div>

        <div className='cad-testimonials'>
            <div className='container'>
                <div className='cad-testimonials-header cad-about-section-right-inner cad-home-banner-inner'>
                    <span>Testimonials</span>
                    <h2>What People Say About Us</h2>
                </div>
                <div className='row cad-testimonials-align'>
                    <Testimonials />
                </div>
            </div>
        </div>

        <Footer />
        </>
    )
}

export default About;