import React , { useEffect } from 'react';
import { Link } from 'react-router-dom';

import Header from './Header';
import Footer from './Footer';
import Title from "./Title";
import ScrollTop from './ScrollTop';
import ContactIcon from './ContactIcon';
import ContactForm from './ContactForm';
import WhatsAppChat from './WhatsAppChat';
import CookiesPopup from './CookiesPopup';

const TermsConditions = () => {

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant"
        });
      }, []);

    return(
        <>
        <Title title='Terms & Conditions | Cad And Render' />
        <ScrollTop />
        <ContactIcon />
        <WhatsAppChat />
        <CookiesPopup />
        <Header />
        <div className='cad-terms-banner cad-home-banner'>
            <img src="/assets/images/terms-conditions-banner.webp" />
            <div className='container clearfix'>
                <div className='cad-home-banner-inner col-12'>
                    <h1>Terms <span>&</span> Conditions</h1>
                </div>
            </div>
        </div>
        <div className='cadTermsConditionsPolicy'>
            <div className='container'>
                <div className='cad-terms-conditions-inner'>
                    <p className='cadTermsContent'>Please read these Terms and Conditions carefully before using our website. These Terms and Conditions govern your use of the website and the services provided by Jewelry CAD Learning. By accessing or using our website, you agree to be bound by these Terms and Conditions. If you do not agree with any part of these Terms and Conditions, please refrain from using our website.</p>
                    <h4 className='cadTermsOrderProcess cadTemsAccessHeading'>Intellectual Property : </h4>
                    <ol>
                        <li>All content, including text, images, videos, tutorials, and course materials, provided on our website is the intellectual property of Jaipur Gems and Jewels and is protected by copyright laws.</li>
                        <li>You may not copy, distribute, modify, reproduce, or create derivative works of the content without our prior written consent.</li>
                    </ol>
                    
                    <h4 className='cadTermsOrderProcess cadTemsAccessHeading'>User Responsibilities : </h4>
                    <ol>
                        <li>You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.</li>
                        <li>You must not share your account credentials with any third party or allow unauthorized  access to your account.</li>
                        <li>You agree to use our website and services for lawful purposes only and in compliance with all applicable laws and regulations.</li>
                    </ol>

                    <h4 className='cadTermsOrderProcess cadTemsAccessHeading'>Course Enrolment and Payment : </h4>
                    <ol>
                        <li>By enrolling in a course, you agree to pay the specified fees associated with the course.</li>
                        <li>Payments are processed securely through our designated payment gateway. We do not store or have access to your payment details.</li>
                        <li>No refund will be issued under any circumstances after the payment is done.</li>
                    </ol>

                    <h4 className='cadTermsOrderProcess cadTemsAccessHeading'>Limitation of Liability : </h4>
                    <ol>
                        <li>Jaipur Gems and Jewels strives to provide accurate and up-to-date information and materials. However, we do not warrant or guarantee the accuracy, completeness, or reliability of any content on our website.</li>
                        <li>We shall not be liable for any direct, indirect, incidental, consequential, or special  damages arising out of or in connection with your use of our website or the services provided, including but not limited to loss of data, loss of profits, or interruption of business.</li>
                    </ol>

                    <h4 className='cadTermsOrderProcess cadTemsAccessHeading'>Third-Party Links : </h4>
                    <ol>
                        <li>Our website may contain links to third-party websites or resources. These links are provided for your convenience, and we do not endorse or have control over the content or practices of these websites.</li>
                        <li>We are not responsible for any damages or losses incurred as a result of accessing or using third-party websites or resources.</li>
                    </ol>

                    <h4 className='cadTermsOrderProcess cadTemsAccessHeading'>Modification and Termination : </h4>
                    <ol>
                        <li>We reserve the right to modify, suspend, or terminate any part of our website or services at any time without prior notice.</li>
                        <li>We may update or modify these Terms and Conditions from time to time. It is your responsibility to review the Terms and Conditions periodically for any changes.</li>
                    </ol>

                    <h4 className='cadTermsOrderProcess cadTemsAccessHeading'>Governing Law : </h4>
                    <ol>
                        <li>These Terms and Conditions shall be governed by and construed in accordance with the laws of Jaipur Jurisdiction.</li>
                        <li>Any disputes arising out of or relating to these Terms and Conditions shall be subject to the exclusive jurisdiction of the courts of Jaipur Jurisdiction.</li>
                    </ol>
                    <p className='cadTermsPaymentPara'>If you have any questions or concerns regarding these Terms and Conditions, please contact us at <a href="mailto:learn@cadandrender.com" target="_blank">learn@cadandrender.com</a>. Thank you for visiting CAD and RENDER (Jaipur Gems and Jewels)!</p>
                </div>
            </div>
        </div>


        <div className='cad-contact-form'>
            <div className='container'>
                <div className='row cad-contact-form-align'>
                    <ContactForm />
                    <div className='cad-contact-form-image col-6'>
                        <div className='cad-contact-form-image-inner'>
                            <Link to="/contact-us"><img src='/assets/images/contact-form-home.jpg' /></Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        
        <Footer />
        </>
    )
}

export default TermsConditions;