import React from 'react';

const Tabs= () => {

  return (
    <>      
      <div className="cad-about-pl-tab-content">
        <div className='cad-about-pl-tab-content-inner row'>
          <div className='cad-about-pl-tab-content-right col-6'>
            <div className='cad-about-pl-tab-content-right-img'>
              <img src="/assets/images/placements.jpg" />
            </div>
          </div>
          <div className='cad-about-pl-tab-content-left col-6'>
            <p>When you complete the learning process, your internship starts which gives you real life experience of making 3D jewelry designs which are practically accurate. You get to learn the perfect application of the commands that are used to make the designs. After your internship ends, you are offered job interviews at different Jewelry Companies so that you may start your professional journey with a boost.</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Tabs;